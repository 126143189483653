.successDialog__paper {
  box-shadow: none;
  padding-top: 80px;
  background: none;
}
.successDialog__box {
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.successDialog__image {
  margin: -110px auto 30px;
}
.successDialog__title {
  margin-bottom: 30px;
}
