.home__short {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  background: var(--white);
  margin-top: -180px;
  position: relative;
  z-index: 10;
  @media screen and (max-width: 1360px) {
    margin-top: -100px;
  }
  @media screen and (max-width: 980px) {
    margin-top: 0;
    grid-template-columns: 1fr;
  }
}
.home__shortVideo {
  margin-bottom: 0;
}
.home__shortTexts {
  display: flex;
  flex-direction: column;
}
.home__shortTitle {
  font-size: var(--h1_FZ);
}
.home__shortText {
  font-size: var(--h2_FZ);
  margin: 40px 0 20px;
  @media screen and (max-width: 980px) {
    margin: 10px 0;
  }
}
.home__company {
  display: grid;
  grid-template-columns: 82px 1fr;
  gap: 8px;
  align-items: center;
  margin-top: auto;
}
.home__main {
  margin-top: 100px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 60px;
  @media screen and (max-width: 1360px) {
    grid-template-columns: 100%;
    margin-top: 30px;
  }
}
.home__content {
  @media screen and (max-width: 1360px) {
    order: 2;
  }
}
.home__cards {
  @media screen and (max-width: 1360px) {
    order: 1;
    overflow-x: auto;
  }
}
.home__cards::-webkit-scrollbar {
  display: none;
}
.home__cardsTitle {
  @media screen and (max-width: 1360px) {
    position: sticky;
    top: 0;
    left: 0;
  }
}
.home__cardsList {
  @media screen and (max-width: 1360px) {
    flex: 1;
    display: flex;
    gap: 15px;
    padding: 0 10px 40px;
    width: calc(var(--cardWidth) * 3 + 30px * 2);
    align-items: flex-start;
  }
}
.home__mainCard {
  position: relative;
  overflow: visible;
}
.home__ceo {
  display: block;
  margin: -90px auto 30px;
  width: 140px;
  height: 140px;
  box-shadow: var(--boxShadow);
  border-radius: 50%;
}
.home__mainBlock:not(:last-child) {
  margin-bottom: 30px;
}
.home__mainTitle {
  font-size: var(--h2_FZ);
  font-weight: 700;
  margin: 20px 0;
}
.home__divider {
  height: 15px;
  border-radius: 20px;
  background: var(--gradient);
  margin-bottom: 20px;
}
.home__text {
  margin-bottom: 20px;
}
.home__listWithIcons {
  margin-top: 60px;
  display: grid;
  gap: 20px;
}
.home__listItemWithIcons {
  display: grid;
  grid-template-columns: 52px 1fr;
  gap: 30px;
  align-items: center;
}
.home__card {
  padding: 0;
  margin-top: 20px;
  max-width: var(--cardWidth);
  @media screen and (max-width: 1360px) {
    width: var(--cardWidth);
  }
  @media screen and (max-width: 980px) {
    width: var(--cardWidth);
  }
}
.home__cardContent {
  padding: 20px;
}
.home__cardTitle {
  font-size: var(--h3_FZ);
  font-weight: 700;
}
.home__cardPrices {
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.home__cardPrice {
  font-size: var(--h2_FZ);
  font-weight: 700;
}
.home__cardSalePrice {
  font-size: 16px;
  color: var(--secondary);
  span {
    text-decoration: line-through;
  }
}
.home__cardOffers {
  margin: 10px 0;
}
.home__payBtn {
  margin-top: 10px;
}
