.videoPlayer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 20px;
}
.videoPlayer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
